import updateTime from './updateTime';
import adjustEmailFontSize from './adjustEmailFontSize';
import submitPasswordForm from './submitPasswordForm';

setInterval(updateTime, 1000);

const resetPasswordForm = document.getElementById(
  'reset_password_form',
);

if (resetPasswordForm) {
  // adjustEmailFontSize();
  resetPasswordForm.addEventListener('submit', (e) =>
    submitPasswordForm(e),
  );
}
