const updateTime = () => {
  const time = document.getElementById('time');
  const hours = new Date().getHours();
  const minutes = new Date()
    .getMinutes()
    .toString()
    .padStart(2, '0');
  time.innerHTML = `${hours}:${minutes}`;
};

export default updateTime;
