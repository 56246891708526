import errorMessage from '../textContent/errorMessage';
import showAlert from './alerts';
import resetPasswordUsingCode from './resetPasswordFunction';

const submitPasswordForm = (e) => {
  e.preventDefault();
  const language = document.body.dataset.appLang || 'pt';
  const { token } = document.body.dataset;
  const resetPassword2 = errorMessage(
    language,
    'resetPassword2',
  );
  const resetPassword3 = errorMessage(
    language,
    'resetPassword3',
  );
  const password =
    document.getElementById('password').value;
  const passwordConfirm = document.getElementById(
    'password_confirm',
  ).value;

  const regEx = new RegExp(
    /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[^a-zA-Z\d]).*$/,
  );
  if (!regEx.test(password)) {
    showAlert(resetPassword2); // Alert the user
    return;
  }

  if (password !== passwordConfirm) {
    showAlert(resetPassword3); // Alert the user
    return;
  }

  resetPasswordUsingCode({
    token,
    password,
    passwordConfirm,
    language,
  });
};

export default submitPasswordForm;
