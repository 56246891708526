const hideAlert = () => {
  const el = document.querySelector('.alert');
  if (el) el.parentElement.removeChild(el);
};
// type is 'success' or 'error'

const showAlert = (message) => {
  hideAlert();
  const markup = `<div class="alert">${message}</div>`;
  document
    .querySelector('body')
    .insertAdjacentHTML('afterbegin', markup);
  window.setTimeout(hideAlert, 3000);
};

export default showAlert;
